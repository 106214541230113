import "core-js/modules/es.array.push.js";
import { mapGetters } from 'vuex';
import common from '@mixins/common';
import scrollList from '@mixins/scrollList';
import getPageList from '@mixins/getPageList';
import filters from '@filters/filters';
import { CheckOutListItem } from './components';
import { getCheckOutListAPI, cancelCheckOut } from '@api/checkOut';
const categoryListData = [{
  id: '',
  label: '全部',
  number: 0,
  children: []
}, {
  id: '5',
  label: '进行中',
  number: 0,
  children: []
}];
export default {
  name: 'CheckOutList',
  mixins: [common, filters, scrollList, getPageList],
  components: {
    CheckOutListItem
  },
  data() {
    return {
      headerTitle: '退房记录',
      categoryInfo: {
        // 分类数据
        value: '',
        //
        listData: categoryListData
      },
      initParams: {
        // 初始化参数
        page: 1,
        size: 20
      },
      searchKey: {} // 搜索参数
    };
  },

  props: {
    status: {
      type: [Number, String],
      default: ''
    }
  },
  computed: {
    ...mapGetters('user', ['projectInfo'])
  },
  methods: {
    // 进入详情
    goDetail(data) {
      let {
        id
      } = data;
      this.$router.push({
        name: 'CheckOutDetail',
        query: {
          id
        }
      });
    },
    // 进入代客退房
    goCheckOut() {
      this.$router.push({
        name: 'CheckOutApply'
      });
    },
    // 取消退房
    async checkOut({
      id
    }) {
      this.$dialog.confirm({
        title: '提示',
        width: '300px',
        message: '请确认是否取消该退房申请'
      }).then(() => {
        cancelCheckOut({
          id
        }).then(res => {
          this.getListData();
        });
      }).catch(() => {
        // on cancel
      });
    },
    handleTabChange({
      name
    }) {
      if (name !== this.status) {
        this.$router.replace({
          name: 'CheckOutList',
          query: {
            status: name
          }
        });
      }
    },
    async getListData({
      isPullDown,
      isShowLoading
    } = {
      isPullDown: false,
      isShowLoading: true
    }) {
      // 获取列表数据
      try {
        this.isLoading = true;
        if (isShowLoading) await this.$store.dispatch('base/SetLoading', true);
        let {
          status,
          listData
        } = this;
        let {
          projectId
        } = this.projectInfo;
        let {
          page,
          size,
          roomNo
        } = this.searchKey;
        let response = await getCheckOutListAPI({
          pageNum: page,
          pageSize: size,
          roomNo: roomNo,
          projectId,
          state: status
        });
        let result = response.data;
        // console.log(result)
        if (`${result.code}` === '10000') {
          // 请求成功
          let resultData = result.data || [];
          // let hasMore = resultData.length > 0
          let hasMore = page < result.pages;
          this.listData = !isPullDown ? [...listData, ...resultData] : [...resultData];
          if (!hasMore) {
            this.isLastPage = true;
            // this.$toast('没有更多数据了！')
          }

          await this.$store.dispatch('checkOut/SetCheckOutList', this.listData);
          await this.handleCallback({
            isError: false,
            hasMore
          });
        } else {
          // 请求失败
          await this.handleCallback({
            isError: true,
            errorMessage: result.msg || '获取列表失败!'
          });
        }
      } catch (error) {
        // console.log(error)
        await this.handleCallback({
          isError: true,
          errorMessage: error.message || '获取列表失败!'
        });
      } finally {
        this.isLoading = false;
        if (isShowLoading) await this.$store.dispatch('base/SetLoading', false);
      }
    },
    async initData() {
      await this.resetParams();
      await this.getListData();
    },
    onSearch() {
      this.resetParams();
      this.getListData();
    }
  },
  filters: {},
  watch: {
    initParams: {
      handler: function (newVal, oldVal) {
        if (JSON.stringify(newVal) !== JSON.stringify(this.searchKey)) {
          this.searchKey = JSON.parse(JSON.stringify(newVal));
        }
      },
      deep: true,
      immediate: true
    },
    status: {
      handler: function (newVal, oldVal) {
        this.searchKey = JSON.parse(JSON.stringify(this.initParams));
        this.categoryInfo.value = newVal;
        this.initData();
      },
      immediate: true
    }
  },
  created() {
    const {
      token,
      projectId,
      userId,
      userName
    } = this.$route.query;
    if (token && projectId) {
      this.$store.commit('user/SET_TOKEN', token);
      this.$store.commit('user/SET_USER_INFO', {
        dcProjectId: projectId,
        userId,
        userName
      });
      this.$store.commit('user/SET_PROJECT_INFO', {
        dcProjectId: projectId
      });
    }
  },
  mounted() {},
  beforeRouteEnter(to, from, next) {
    // console.log(to)
    // console.log(from)
    next();
  },
  beforeRouteUpdate(to, from, next) {
    // console.log(to)
    // console.log(from)
    next();
  }
};