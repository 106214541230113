import filters from '@filters/filters';
import { dateFilter } from '@utils/utils';
import { sexMap, statusMap } from '@/dictionaries/checkOut';
export default {
  name: 'CheckOutListItem',
  mixins: [filters],
  components: {},
  data() {
    return {
      headerContentField: {
        isShow: true,
        title: '离店时间：',
        key: 'leaveTime',
        filterMethod: dateFilter
      },
      headerExtraField: {
        isShow: true,
        title: '',
        key: 'status',
        filterMethod: data => data == 0 ? '取消退房申请' : '',
        color: 'orange'
      },
      footerContentField: {
        isShow: true,
        title: '退房进度'
      },
      footerExtraField: {
        isShow: true,
        title: '',
        key: 'status',
        filterMethod: data => statusMap[data]['text'],
        getColorMethod: data => statusMap[data]['colorType']
      },
      bodyFields: [{
        title: '房号：',
        key: 'roomNo'
      }, {
        title: '租户姓名：',
        key: 'customerName'
      }, {
        title: '租户联系方式：',
        key: 'customerPhone'
      }, {
        title: '上门验房时间：',
        key: 'checkTime'
      }, {
        title: '创建时间：',
        key: 'userCreateTime'
      }]
    };
  },
  props: {
    detailInfo: {
      type: Object,
      require: true,
      default() {
        return {};
      }
    }
  },
  computed: {},
  methods: {
    goDetail() {
      this.$emit('go-detail', this.detailInfo);
    },
    checkOut() {
      this.$emit('check-out', this.detailInfo);
    },
    async initData() {}
  },
  filters: {},
  watch: {},
  created() {
    this.initData();
  },
  mounted() {}
};